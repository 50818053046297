<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true);"
      >
      {{ $t('Add Booking') }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Room') }}</span>
        </h5>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedRooms"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              @change="emit_now"
              v-for="item in rooms"
              :key="item.label"
              name="event-filter"
              :value="item.id"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

      </div>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Staff') }}</span>
        </h5>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedStaffs"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              @change="emit_now"
              v-for="item in staffs"
              :key="item.value"
              name="event-filter"
              :value="item.value"
              class="mb-1"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  created() {
  //  console.clear()
    this.get_room()
    this.get_staff()
  },
  methods: {
    emit_now() {
      var room_string = this.selectedRooms.join(',')
      var staff_string = this.selectedStaffs.join(',')

      this.$emit('fetch',room_string,staff_string)
    },
    get_room() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/rooms/?enabled=y&center_id=`+ this.$store.getters['center/currentCenterId'])
        .then(res => {
          if (process.env.VUE_APP_VERSION != res.data.version) {
            this.$router.go(0);
          }
          this.rooms = res.data.rooms
        })
    },
    get_staff() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staffs/select/?role=beautician`)
        .then(res => {
          this.staffs = res.data
        })
    },
  },
  data() {
    return {
      calendarOptions:[],
      staffs: [],
      rooms : [],
      selectedRooms:[],
      selectedStaffs:[]
    }
  },
}
</script>

<style>

</style>
